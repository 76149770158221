<template>
  <div>
    <v-container fluid>
      <v-row align="center">
        <v-col cols="12">
          <h1
            class="brand-title pa-4"
            :style="
              $vuetify.breakpoint.smAndUp
                ? fit_title_font_dynamically('Sorry')
                : ''
            "
          >
            Sorry
          </h1>
        </v-col>
      </v-row>
      <v-row class="main-row primary white--text py-4">
        <v-col cols="12" md="2">
          <p class="code">404</p>
        </v-col>
        <v-col cols="12" md="10" class="msg-wrapper">
          <h2 class="display-3 font-weight-bold pt-6 pb-2">Page not found</h2>
          <p>
            Ooops... not sure where you wandered, but we need you to come back!
          </p>
          <v-btn
            class="mr-0 font-weight-bold primary--text"
            color="white"
            to="/"
            >Home Page</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  methods: {
    fit_title_font_dynamically(text) {
      const characters = text.length;
      let size = 78 / characters;

      if (size > 10) size = 10;

      return `font-size: ${size}vw;`;
    },
  },
};
</script>

<style lang="scss" scoped>
.code {
  text-align: center;
  font-size: 5rem;
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    text-align: right;
    font-size: 9.5rem;
  }
  font-weight: 800;
  color: white;
}
.msg-wrapper {
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    text-align: center;
  }
}
.footer-wrapper {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
.main-row {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    margin-top: 3rem;
  }
}
.brand-title {
  line-height: 1;
  position: relative;
  font-size: 2.25rem;
  text-transform: uppercase;
  display: block;
  font-family: "Open Sans";
  font-weight: 800;
}
</style>
